import React from 'react';
import { useMsal } from "@azure/msal-react";
import { useHistory } from 'react-router-dom';

const Logout = () => {
    const { instance } = useMsal();
    const history = useHistory();

    const handleLogout = () => {
        instance.logoutPopup().then(() => {
            history.push('/');
        }).catch(e => {
            console.error(e);
        });
    };

    return (
        <button onClick={handleLogout}>Sign out</button>
    );
};

export default Logout;