import axios from 'axios';

export const handleStartInstall = async (setAuthToken, history) => {
    console.log('Starting install process...'); // Log before API call
    try {
        const response = await axios.post('/api/authenticate');
        if (response && response.data) {
            const { access_token } = response.data;
            if (access_token) {
                setAuthToken(access_token);
                history.push('/install');
            } else {
                alert('Failed to retrieve access token');
            }
        } else {
            alert('Failed to retrieve access token');
        }
    } catch (error) {
        alert('Failed to start install');
    }
};