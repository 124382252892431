import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { WebSocketContext } from '../context/WebSocketContext';
import { AdminSiteIdContext } from '../context/AdminSiteIdContext';
import websocketService from '../websocket';
import Modal from './Modal';
import Typewriter from 'typewriter-effect';

const Find = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [responseDetails, setResponseDetails] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState('');
    const history = useHistory();
    const { authToken } = useContext(AuthContext);
    const { messages, wsConnected } = useContext(WebSocketContext);
    const { adminSiteId, setAdminSiteId, setCameraSerials, setBigmacSerials } = useContext(AdminSiteIdContext);

    useEffect(() => {
        if (messages && messages.length > 0) {
            const latestMessage = messages[messages.length - 1];
            if (latestMessage.success) {
                setResponseDetails(latestMessage.data);
                setLoadingMessage(''); // Clear the loading message
            }
        }
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!wsConnected) {
            alert('WebSocket is not connected. Please try again.');
            return;
        }

        setIsLoading(true);
        setResponseDetails(null); // Clear previous response details
        setLoadingMessage(''); // Clear loading message

        try {
            // Ensure the UUID is available before making the request
            const uuid = websocketService.uuid;
            if (!uuid) {
                throw new Error('UUID is not set. Unable to proceed with the request.');
            }

            const response = await axios.post('/api/search', {
                admin_site_id: adminSiteId,
                uuid: uuid  // Include the UUID in the request
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            console.log('Response from /search:', response.data);
            if (response.data && response.data.task_id) {
                setLoadingMessage(`Task started for site ${adminSiteId}. Waiting for results...`);
                setShowModal(true); // Show the modal with loading message
            }
        } catch (error) {
            console.error('Failed to make API call to /search:', error);
            alert('Failed to start search');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoHome = () => {
        setAdminSiteId(''); // Clear Admin Site ID
        setCameraSerials([]); // Clear camera serials
        setBigmacSerials([]); // Clear bigmac serials
        history.push('/home');
    };

    const handleGoDecisionPage = () => {
        history.push('/decision');
    };

    const handleGoInstall = () => {
        history.push('/install');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setResponseDetails(null); // Clear response details when closing the modal
        setLoadingMessage(''); // Clear loading message when closing the modal
    };

    const handleModalResponse = (response) => {
        setCameraSerials(response.devices.cameras); // Store camera serials in context
        setBigmacSerials(response.devices.bigmac); // Store bigmac serials in context
        handleCloseModal();
    };

    const renderDevices = (devices) => {
        if (!devices) return <p>No devices found.</p>;

        return (
            <div>
                {Object.keys(devices).map((category) => (
                    <div key={category}>
                        <h3>{category.toUpperCase()}</h3>
                        <ul>
                            {devices[category].map((device, index) => (
                                <li key={index}>{device}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        );
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the form from submitting on Enter keypress
        }
    };

    return (
        <div className="container" onKeyPress={handleKeyPress}>
            <h1>Find Devices</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Admin Site ID:</label>
                    <input
                        type="text"
                        value={adminSiteId}
                        onChange={(e) => setAdminSiteId(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading} className="primary-button">
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </form>
            <div className="button-group">
                <button onClick={handleGoHome} className="primary-button">Go Home</button>
                <button onClick={handleGoDecisionPage} className="primary-button">Install Options</button>
                <button onClick={handleGoInstall} className="primary-button">Enclosure Config</button>
            </div>

            <Modal show={showModal} handleClose={handleCloseModal}>
                <h2>Response Details</h2>
                {loadingMessage && (
                    <p>
                        {loadingMessage}
                        <Typewriter
                            options={{
                                strings: ['....', '....', '....'],
                                autoStart: true,
                                loop: true,
                                delay: 500,
                                deleteSpeed: 500,
                            }}
                        />
                    </p>
                )}
                {responseDetails && (
                    <div>
                        {renderDevices(responseDetails.devices)}
                        <button onClick={() => handleModalResponse(responseDetails)} className="primary-button">Proceed</button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Find;