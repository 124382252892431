import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ show, handleClose, children, className }) => {
    if (!show) {
        return null;
    }

    return (
        <div className={`modal ${className}`} onClick={handleClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <button className="modal-close-button" onClick={handleClose}>
                        &times;
                    </button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer">
                    <button onClick={handleClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

Modal.defaultProps = {
    className: ''
};

export default Modal;