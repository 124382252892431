import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { AdminSiteIdContext } from '../context/AdminSiteIdContext';
import Modal from './Modal';
import '../styles/LaneGate.css';

const LaneGate = () => {
    const [laneId, setLaneId] = useState('');
    const [direction, setDirection] = useState('');
    const [bigmacSerial, setBigmacSerial] = useState('');
    const [relay, setRelay] = useState('1');
    const [gateType, setGateType] = useState('pgate');
    const [isGate, setIsGate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [responseDetails, setResponseDetails] = useState(null);
    const [formStatus, setFormStatus] = useState({}); // Track form submission status
    const history = useHistory();
    const { authToken } = useContext(AuthContext);
    const { adminSiteId, setAdminSiteId, cameraSerials, setCameraSerials, bigmacSerials, setBigmacSerials } = useContext(AdminSiteIdContext);

    useEffect(() => {
        console.log('AdminSiteIdContext values:', { adminSiteId, cameraSerials, bigmacSerials });
    }, [adminSiteId, cameraSerials, bigmacSerials]);

    const handleSubmit = async (index, laneData) => {
        setIsLoading(true);
        setResponseDetails(null); // Clear previous response details
        try {
            const response = await axios.post('/api/lane-pair', laneData, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            console.log('Response from /lane-pair:', response.data);
            setResponseDetails(response.data);
            setFormStatus(prevStatus => ({
                ...prevStatus,
                [index]: response.status === 200 ? 'confirmed' : 'failed'
            }));
            setShowModal(true);
        } catch (error) {
            console.error('Failed to make API call to /lane-pair:', error);
            setFormStatus(prevStatus => ({
                ...prevStatus,
                [index]: 'failed'
            }));
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoBack = () => {
        history.push('/decision');
    };

    const handleGoHome = () => {
        setAdminSiteId(''); // Clear Admin Site ID
        setCameraSerials([]); // Clear camera serials
        setBigmacSerials([]); // Clear bigmac serials
        history.push('/home');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setResponseDetails(null); // Clear previous response details when closing the modal
    };

    const renderLaneForms = () => {
        return cameraSerials.map((serial, index) => (
            <form key={index} onSubmit={(e) => {
                e.preventDefault();
                const laneData = {
                    admin_site_id: adminSiteId,
                    lane_id: laneId,
                    direction,
                    serial_number: serial,
                };
                if (isGate) {
                    laneData.bigmac_serial = bigmacSerial;
                    laneData.relay = relay;
                    laneData.gate_type = gateType;
                }
                handleSubmit(index, laneData);
            }}>
                <div>
                    <label>Lane ID:</label>
                    <input
                        type="text"
                        value={laneId}
                        onChange={(e) => setLaneId(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Direction:</label>
                    <input
                        type="text"
                        value={direction}
                        onChange={(e) => setDirection(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Camera Serial Number:</label>
                    <input
                        type="text"
                        value={serial}
                        readOnly
                    />
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={isGate}
                            onChange={(e) => setIsGate(e.target.checked)}
                        />
                        Gate?
                    </label>
                </div>
                {isGate && (
                    <>
                        <div>
                            <label>Bigmac Serial:</label>
                            <select
                                value={bigmacSerial}
                                onChange={(e) => setBigmacSerial(e.target.value)}
                                required
                            >
                                <option value="">Select Bigmac Serial</option>
                                {bigmacSerials.map((bigmac, idx) => (
                                    <option key={idx} value={bigmac}>{bigmac}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Relay:</label>
                            <select
                                value={relay}
                                onChange={(e) => setRelay(e.target.value)}
                                required
                            >
                                {[...Array(7).keys()].map(i => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label>Gate Type:</label>
                            <select
                                value={gateType}
                                onChange={(e) => setGateType(e.target.value)}
                                required
                            >
                                <option value="pgate">Pgate</option>
                                <option value="sgate">Sgate</option>
                            </select>
                        </div>
                    </>
                )}
                <button
                    type="submit"
                    disabled={isLoading}
                    className={`primary-button ${formStatus[index] === 'confirmed' ? 'button-confirmed' : formStatus[index] === 'failed' ? 'button-failed' : ''}`}
                >
                    {formStatus[index] === 'confirmed' ? 'Confirmed' : 'Submit'}
                </button>
            </form>
        ));
    };

    return (
        <div className="container">
            <h1 className="title">Pair Lane</h1>
            {renderLaneForms()}
            <div className="button-group">
                <button onClick={handleGoBack} className="primary-button">Install Options</button>
                <button onClick={handleGoHome} className="primary-button">Go Home</button>
            </div>

            <Modal show={showModal} handleClose={handleCloseModal}>
                <h2>Response Details</h2>
                {responseDetails && (
                    <div>
                        <p>Status: {responseDetails.status}</p>
                        <p>Details: {responseDetails.details}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default LaneGate;