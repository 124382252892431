import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { WebSocketContext } from '../context/WebSocketContext';
import { AuthContext } from '../context/AuthContext';
import websocketService from '../websocket'; // Import the WebSocketService
import axios from 'axios';
import Modal from './Modal';
import Typewriter from 'typewriter-effect';
import '../styles/Online.css';

const Online = () => {
    const { messages, clearMessages } = useContext(WebSocketContext);
    const { setAuthToken } = useContext(AuthContext);
    const history = useHistory();
    const [parsedMessages, setParsedMessages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showWaiting, setShowWaiting] = useState(true);
    const [isFirstWaiting, setIsFirstWaiting] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Processing...');
    const [isFinalResultReceived, setIsFinalResultReceived] = useState(false);

    useEffect(() => {
        if (messages && Array.isArray(messages) && messages.length > 0) {
            console.log('Received messages:', messages);
            try {
                const newParsedMessages = messages.map((msg) => {
                    if (typeof msg === 'string') {
                        return JSON.parse(msg);
                    }
                    return msg;
                });

                const lastMessage = newParsedMessages[newParsedMessages.length - 1];
                if (lastMessage.data.isFinalResult) {
                    setIsFinalResultReceived(true); // Flag as final result
                }

                setParsedMessages(newParsedMessages);
                setLoadingMessage(lastMessage.data.details[0].message); // Update loading message

                setShowWaiting(false); // Show the new message immediately
                console.log('Parsed messages set:', newParsedMessages);
            } catch (error) {
                console.error('Failed to parse messages:', error);
            }
        }
    }, [messages]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            handleCloseModal();
        });

        return () => {
            unlisten();
            handleCloseModal();
        };
    }, [history]);

    useEffect(() => {
        let timer;
        if (!showWaiting && isFirstWaiting && !isFinalResultReceived) {
            timer = setTimeout(() => {
                setShowWaiting(true);
                setIsFirstWaiting(false);
                setParsedMessages([]); // Clear parsed messages when moving to the next phase
                setLoadingMessage('Processing...');
            }, 60000); // 1 minute
        }
        return () => clearTimeout(timer);
    }, [showWaiting, isFirstWaiting, isFinalResultReceived]);

    const triggerServerAction = async () => {
        if (!websocketService.socket || websocketService.socket.readyState !== WebSocket.OPEN) {
            alert('WebSocket is not connected. Please try again.');
            return;
        }

        const uuid = websocketService.uuid;
        if (!uuid) {
            alert('UUID is not set. Please try again.');
            return;
        }

        setShowModal(true);
        setShowWaiting(true);
        setLoadingMessage('Removing Custom script, Getting Permanent Node ID');

        try {
            const response = await axios.post('/api/second-server-action', { uuid: uuid }); // Include UUID in the request
            console.log('Response from second server action:', response.data);
            if (response.status === 200 && response.data.message === 'Searching for new routers') {
                setLoadingMessage('Moving to Private Network');
            } else {
                console.error('Unexpected response:', response.data);
                setLoadingMessage('Unexpected response received');
            }
        } catch (error) {
            console.error('Error triggering server action', error);
            setLoadingMessage('Error triggering server action');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setParsedMessages([]);
        setShowWaiting(true);
        setIsFirstWaiting(true);
        setLoadingMessage('Processing...');
        setIsFinalResultReceived(false); // Reset final result flag
    };

    const extractDetails = (data) => {
        if (!data || !data.details || !Array.isArray(data.details)) {
            return [];
        }
        return data.details.map(detail => ({
            status: detail.status,
            message: detail.message
        }));
    };

    const handleGoHome = () => {
        handleCloseModal(); // Ensure modal is closed and state is reset when navigating home
        history.push('/home');
    };

    return (
        <div className="container">
            <h1 className="title">Enclosure Online?</h1>
            <button onClick={triggerServerAction} className="primary-button">Move To Private</button>
            {showModal && (
                <Modal show={showModal} handleClose={handleCloseModal} className="results-modal">
                    <h2 className="subheading">Processing...</h2>
                    {showWaiting ? (
                        isFirstWaiting ? (
                            <div>
                                <p className="message">Removing Custom script, Getting Permanent Node ID</p>
                                <p className="typing-effect">
                                    <Typewriter
                                        options={{
                                            strings: ['Running initial configuration.....'],
                                            autoStart: true,
                                            loop: true,
                                            delay: 50,
                                            deleteSpeed: 25,
                                            pauseFor: 5000,
                                        }}
                                    />
                                </p>
                            </div>
                        ) : (
                            <div>
                                <p className="message">Moving to Private Network</p>
                                <p className="typing-effect">
                                    <Typewriter
                                        options={{
                                            strings: ['Applying config. Pushing to private. Please Wait....'],
                                            autoStart: true,
                                            loop: true,
                                            delay: 50,
                                            deleteSpeed: 25,
                                            pauseFor: 5000,
                                        }}
                                    />
                                </p>
                            </div>
                        )
                    ) : (
                        <>
                            {parsedMessages.length > 0 ? (
                                parsedMessages.map((message, index) => {
                                    const details = extractDetails(message.data); // Access data.details
                                    return (
                                        <div key={index} className="results">
                                            {details.map((detail, detailIndex) => (
                                                <div key={detailIndex}>
                                                    <p>Status: {detail.status}</p>
                                                    <p>Message: {detail.message}</p>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })
                            ) : (
                                <p className="message">{loadingMessage}</p>
                            )}
                        </>
                    )}
                </Modal>
            )}
            <div className="button-group">
                <button onClick={handleGoHome} className="primary-button">Home</button>
            </div>
        </div>
    );
};

export default Online;