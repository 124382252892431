import React from 'react';
import { useMsal } from "@azure/msal-react";
import { useHistory } from 'react-router-dom';
import { loginRequest } from "../config/authConfig";

const SignInComponent = () => {
    const { instance } = useMsal();
    const history = useHistory();

    const handleSignIn = async () => {
        try {
            await instance.loginRedirect(loginRequest);
            // No need to handle history push here as it will be handled after redirect
        } catch (e) {
            console.error("Authentication error: ", e);
        }
    };

    return (
        <div className="container">
            <h1 className="title">Sign In</h1>
            <button onClick={handleSignIn} className="primary-button">Sign In with Microsoft</button>
        </div>
    );
};

export default SignInComponent;