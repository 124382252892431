import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { WebSocketContext } from '../context/WebSocketContext';
import { AdminSiteIdContext } from '../context/AdminSiteIdContext';
import websocketService from '../websocket';
import Modal from './Modal';
import '../styles/Finish.css';

const Finish = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [responseDetails, setResponseDetails] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState('');
    const history = useHistory();
    const { authToken } = useContext(AuthContext);
    const { messages, wsConnected } = useContext(WebSocketContext);
    const { adminSiteId, setAdminSiteId } = useContext(AdminSiteIdContext);

    useEffect(() => {
        if (messages && messages.length > 0) {
            const latestMessage = messages[messages.length - 1];
            setResponseDetails(latestMessage.data);
            setLoadingMessage(''); // Clear the loading message
            setShowModal(true); // Show modal when a new message is received
        }
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!wsConnected) {
            alert('WebSocket is not connected. Please try again.');
            return;
        }

        setIsLoading(true);

        try {
            // Ensure the UUID is available before making the request
            const uuid = websocketService.uuid;
            if (!uuid) {
                throw new Error('UUID is not set. Unable to proceed with the request.');
            }

            const response = await axios.post('/api/final-step', {
                admin_site_id: adminSiteId,
                uuid: uuid  // Include the UUID in the request
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            console.log('Response from /final-step:', response.data);
            if (response.data.task_id) {
                setLoadingMessage(`Task started for site ${adminSiteId}. Waiting for results...`); // Set loading message
                setShowModal(true); // Show the modal with loading message
            }
        } catch (error) {
            console.error('Failed to make API call to /final-step:', error);
            alert('Failed to start final config');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoBack = () => {
        history.push('/decision');
    };

    const handleGoHome = () => {
        setAdminSiteId(''); // Clear Admin Site ID
        history.push('/home');
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div className="container">
            <h1>Finish Page</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Admin Site ID:</label>
                    <input
                        type="text"
                        value={adminSiteId}
                        onChange={(e) => setAdminSiteId(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading} className="primary-button">
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </form>
            <div className="button-group">
                <button onClick={handleGoBack} className="primary-button">Install Options</button>
                <button onClick={handleGoHome} className="primary-button">Go Home</button>
            </div>

            <Modal show={showModal} handleClose={handleCloseModal}>
                <h2>Response Details</h2>
                {loadingMessage && <p>{loadingMessage}</p>}
                {responseDetails && (
                    <div>
                        <p>Status: {responseDetails.status}</p>
                        {responseDetails.service_status && <p>Service Status: {JSON.stringify(responseDetails.service_status)}</p>}
                        {responseDetails.message && <p>Message: {responseDetails.message}</p>}
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default Finish;