class WebSocketService {
    constructor() {
        this.socket = null;
        this.listeners = {};
        this.uuid = null;
        this.url = process.env.REACT_APP_WEBSOCKET_URL;
        this.connect = this.connect.bind(this);
    }

    connect() {
        this.socket = new WebSocket(this.url);

        this.socket.onopen = () => {
            console.log('WebSocket connected');
        };

        this.socket.onmessage = (message) => {
            const parsedMessage = JSON.parse(message.data);

            if (parsedMessage.type === 'uuid') {
                this.uuid = parsedMessage.uuid; // Store the received UUID
                console.log(`Received UUID from server: ${this.uuid}`);
            } else {
                Object.values(this.listeners).forEach(listener => listener(parsedMessage));
            }
        };

        this.socket.onclose = (e) => {
            console.log(`WebSocket disconnected. Reason: ${e.reason}, Code: ${e.code}`);
            if (e.code !== 1000) {
                setTimeout(() => {
                    console.log('Reconnecting WebSocket...');
                    this.connect();
                }, 5000);
            }
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error', error);
        };
    }

    addListener(id, callback) {
        this.listeners[id] = callback;
    }

    removeListener(id) {
        delete this.listeners[id];
    }

    sendMessage(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            const messageWithUuid = { ...message, uuid: this.uuid };
            this.socket.send(JSON.stringify(messageWithUuid));
        } else {
            console.error('WebSocket is not open');
        }
    }
}

const websocketService = new WebSocketService();
export default websocketService;