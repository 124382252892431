import React, { createContext, useEffect, useState } from 'react';
import websocketService from '../websocket';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [wsConnected, setWsConnected] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        websocketService.connect();

        websocketService.addListener('general', (message) => {
            setMessages(prevMessages => [...prevMessages, message]);
        });

        websocketService.socket.onopen = () => {
            console.log('WebSocket connected');
            setWsConnected(true);
        };

        websocketService.socket.onclose = (e) => {
            console.log(`WebSocket disconnected. Reason: ${e.reason}, Code: ${e.code}`);
            setWsConnected(false);
            if (e.code !== 1000) {
                setTimeout(() => {
                    console.log('Reconnecting WebSocket...');
                    websocketService.connect();
                }, 5000);
            }
        };

        return () => {
            websocketService.removeListener('general');
            if (websocketService.socket) {
                websocketService.socket.close(1000, 'Component unmounted');
            }
        };
    }, []);

    return (
        <WebSocketContext.Provider value={{ wsConnected, messages }}>
            {children}
        </WebSocketContext.Provider>
    );
};