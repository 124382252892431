import React from 'react';
import Typewriter from 'typewriter-effect';
import '../styles/TypingText.css';

const TypingText = () => {
    return (
        <div className="typing-text">
            <h1>DNS Install App</h1>
            <Typewriter
                options={{
                    strings: ['Click here to start'],
                    autoStart: true,
                    loop: true,
                    delay: 75,
                    pauseFor: 60000,
                }}
            />
        </div>
    );
};

export default TypingText;