import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { AdminSiteIdContext } from '../context/AdminSiteIdContext';
import Modal from './Modal';
import '../styles/CameraImage.css';

const CaptureImage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [isIntervalCapture, setIsIntervalCapture] = useState(false);
    const { authToken } = useContext(AuthContext);
    const { adminSiteId, cameraSerials } = useContext(AdminSiteIdContext);
    const history = useHistory();

    const handleCaptureImage = async (cameraSerial) => {
        setIsLoading(true);
        setImageUrl(''); // Clear previous image URL

        try {
            const response = await axios.post('/api/capture-image', {
                admin_site_id: adminSiteId,
                camera_serial: cameraSerial
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            setImageUrl(response.data.s3_presigned_url);
            setShowModal(true);
        } catch (error) {
            console.error('Failed to capture image:', error);
            alert('Failed to capture image');
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartIntervalCapture = async (cameraSerial) => {
        setIsLoading(true);
        setImageUrl(''); // Clear previous image URL
        setIsIntervalCapture(true);

        try {
            const response = await axios.post('/api/capture-image', {
                admin_site_id: adminSiteId,
                camera_serial: cameraSerial,
                interval_seconds: 5,
                duration_minutes: 2
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            const taskId = response.data.task_id;

            // Fetch the first presigned URL using the task ID
            axios.get(`/api/get-latest-image`, {
                params: {
                    admin_site_id: adminSiteId,
                    camera_serial: cameraSerial
                },
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            }).then(response => {
                setImageUrl(response.data.s3_presigned_url);
            }).catch(error => {
                console.error('Failed to fetch latest image:', error);
            });

            // Check for new images every 5 seconds
            const interval = setInterval(() => {
                axios.get(`/api/get-latest-image`, {
                    params: {
                        admin_site_id: adminSiteId,
                        camera_serial: cameraSerial
                    },
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }).then(response => {
                    setImageUrl(response.data.s3_presigned_url);
                }).catch(error => {
                    console.error('Failed to fetch latest image:', error);
                });
            }, 5000);

            setTimeout(() => {
                clearInterval(interval);
                setIsIntervalCapture(false);
                setIsLoading(false);
            }, 1 * 60 * 1000); // Stop after 1 minutes

            setShowModal(true);
        } catch (error) {
            console.error('Failed to start interval capture:', error);
            alert('Failed to start interval capture');
            setIsIntervalCapture(false);
            setIsLoading(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setImageUrl(''); // Clear image URL when closing the modal
    };

    const handleGoDecisionPage = () => {
        history.push('/decision');
    };

    return (
        <div className="container">
            <h1 className="title">Camera Views</h1>
            {cameraSerials.length > 0 ? (
                <div>
                    {cameraSerials.map((serial, index) => (
                        <div key={index} className="camera-serial-item">
                            <h3>Camera Serial: {serial}</h3>
                            <div className="camera-buttons">
                                <button
                                    type="button"
                                    onClick={() => handleCaptureImage(serial)}
                                    disabled={isLoading || isIntervalCapture}
                                    className="primary-button"
                                >
                                    {isLoading ? 'Capturing...' : 'Capture Image'}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => handleStartIntervalCapture(serial)}
                                    disabled={isLoading || isIntervalCapture}
                                    className="primary-button"
                                >
                                    {isIntervalCapture ? 'Capturing Live view...' : 'Start Live Capture'}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No camera serials found.</p>
            )}
            <div className="button-group">
                <button onClick={handleGoDecisionPage} className="primary-button">Install Options</button>
            </div>

            <Modal show={showModal} handleClose={handleCloseModal}>
                <h2>Captured Images</h2>
                {imageUrl ? (
                    <div>
                        <img src={imageUrl} alt="Captured" />
                    </div>
                ) : (
                    'Loading...'
                )}
            </Modal>
        </div>
    );
};

export default CaptureImage;