import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { WebSocketContext } from '../context/WebSocketContext';
import { AdminSiteIdContext } from '../context/AdminSiteIdContext';
import websocketService from '../websocket';
import Modal from './Modal';
import Typewriter from 'typewriter-effect';
import '../styles/Install.css';

const Install = () => {
    const { adminSiteId, setAdminSiteId } = useContext(AdminSiteIdContext);
    const [enclosureSerial, setEnclosureSerial] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [responseDetails, setResponseDetails] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [typingDone, setTypingDone] = useState(false);
    const history = useHistory();
    const { authToken } = useContext(AuthContext);
    const { messages, wsConnected } = useContext(WebSocketContext);

    useEffect(() => {
        console.log('AdminSiteIdContext values:', { adminSiteId, setAdminSiteId });
    }, []);

    useEffect(() => {
        if (messages && messages.length > 0) {
            const latestMessage = messages[messages.length - 1];
            if (latestMessage.success) {
                setResponseDetails(latestMessage.data);
                setLoadingMessage('');
            }
        }
    }, [messages]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!wsConnected) {
            alert('WebSocket is not connected. Please try again.');
            return;
        }

        setIsLoading(true);
        setResponseDetails(null);

        try {
            // Ensure the UUID is available before making the request
            const uuid = websocketService.uuid;
            if (!uuid) {
                throw new Error('UUID is not set. Unable to proceed with the request.');
            }

            const response = await axios.post('/api/enclosure-online', {
                admin_site_id: adminSiteId,
                enclosure_serial: enclosureSerial,
                uuid: uuid  // Include the UUID in the request
            }, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });

            console.log('Response from /enclosure-online:', response.data);
            if (response.data && response.data.task_id) {
                setLoadingMessage(`Task started for site ${adminSiteId} and enclosure ${enclosureSerial}. Waiting for results`);
                setShowModal(true);
                setEnclosureSerial('');
            }
        } catch (error) {
            console.error('Failed to make API call to /enclosure-online:', error);
            alert('Failed to start install');
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoHome = () => {
        setAdminSiteId('');
        history.push('/home');
    };

    const handleGoFind = () => {
        history.push('/find');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setResponseDetails(null);
        setLoadingMessage('');
    };

    return (
        <div className="container">
            <h1 className="title">Enclosure Config</h1>
            <div className="subheading">
                {!typingDone && (
                    <Typewriter
                        options={{
                            strings: ['need more input....'],
                            autoStart: true,
                            loop: false,
                            delay: 75,
                            pauseFor: 60000,
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .typeString('need more input....')
                                .callFunction(() => {
                                    setTypingDone(true);
                                })
                                .start();
                        }}
                    />
                )}
                {typingDone && 'need more input'}
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Admin Site ID:</label>
                    <input
                        type="text"
                        value={adminSiteId}
                        onChange={(e) => setAdminSiteId(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Enclosure Serial:</label>
                    <input
                        type="text"
                        value={enclosureSerial}
                        onChange={(e) => setEnclosureSerial(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                </button>
            </form>
            <div className="button-group">
                <button onClick={handleGoHome}>Go Home</button>
                <button onClick={handleGoFind}>Find Devices</button>
            </div>

            <Modal show={showModal} handleClose={handleCloseModal}>
                <h2>Response Details</h2>
                {loadingMessage && (
                    <p>
                        {loadingMessage}
                        <Typewriter
                            options={{
                                strings: ['....', '....', '....'],
                                autoStart: true,
                                loop: true,
                                delay: 500,
                                deleteSpeed: 500,
                            }}
                        />
                    </p>
                )}
                {responseDetails ? (
                    <div>
                        <p>Status: {responseDetails.status}</p>
                        <p>Static Route: {responseDetails.static_route}</p>
                        <p>Download Speed: {responseDetails.download_speed}</p>
                        <p>Upload Speed: {responseDetails.upload_speed}</p>
                    </div>
                ) : (
                    !loadingMessage && <p>No response details available yet.</p>
                )}
            </Modal>
        </div>
    );
};

export default Install;