import React from 'react';
import { useHistory } from 'react-router-dom';

const DecisionPage = () => {
    const history = useHistory();

    const handleGoLaneGate = () => {
        history.push('/lane');
    };

    const handleGoFinish = () => {
        history.push('/finish');
    };

    const handleGoHome = () => {
        history.push('/home');
    };

    const handleGoFind = () => {
        history.push('/find');
    };

    const handleCameraImage = () => {
        history.push('/camera')
    };

    return (
        <div className="container">
            <h1>Install Options</h1>
            <div className="button-group">
                <button onClick={handleGoLaneGate}>Lane Assignment</button>
                <button onClick={handleCameraImage}>Camera Views</button>
                <button onClick={handleGoFinish}>Finish Install</button>
                <button onClick={handleGoFind}>Find Devices</button>
                <button onClick={handleGoHome}>Go Home</button>
            </div>
        </div>
    );
};

export default DecisionPage;