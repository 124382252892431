import React, { createContext, useState } from 'react';

export const AdminSiteIdContext = createContext();

export const AdminSiteIdProvider = ({ children }) => {
    const [adminSiteId, setAdminSiteId] = useState('');
    const [cameraSerials, setCameraSerials] = useState([]);
    const [bigmacSerials, setBigmacSerials] = useState([]);

    console.log('AdminSiteIdProvider rendered'); // Debugging statement

    return (
        <AdminSiteIdContext.Provider value={{ adminSiteId, setAdminSiteId, cameraSerials, setCameraSerials, bigmacSerials, setBigmacSerials }}>
            {children}
        </AdminSiteIdContext.Provider>
    );
};