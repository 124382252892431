import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import Logout from './Logout';
import { AuthContext } from '../context/AuthContext';
import { handleStartInstall } from '../installHelpers';
import '../styles/Home.css';

const Home = () => {
    const history = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const { setAuthToken } = useContext(AuthContext);

    const navigateToOnline = () => {
        history.push('/online');
    };

    return (
        <div className="container">
            <h1>Home Page</h1>
            {isAuthenticated ? (
                <>
                    <button onClick={navigateToOnline}>Enclosure Online?</button>
                    <button onClick={() => handleStartInstall(setAuthToken, history)}>Enclosure Config</button>
                    <Logout />
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default Home;