import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './config/authConfig';
import Home from './components/Home';
import Install from './components/Install';
import Online from './components/Online';
import Find from './components/Find';
import DecisionPage from './components/DecisionPage';
import LaneGate from './components/LaneGate';
import CameraImage from './components/CameraImage';
import Finish from './components/Finish';
import { AuthProvider } from './context/AuthContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { AdminSiteIdProvider } from './context/AdminSiteIdContext';
import SignInComponent from './components/SignInComponent';
import './styles/App.css';
import './styles/global.css';
import TypingText from './components/TypingText';

const basename = process.env.REACT_APP_BASENAME || '/dns';

const msalInstance = new PublicClientApplication(msalConfig);

const AppContent = () => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/home');
    }
  }, [isAuthenticated, history]);

  if (!isAuthenticated) {
    return <SignInComponent />;
  }

  return (
    <Switch>
      <Route path="/home" exact component={Home} />
      <Route path="/install" component={Install} />
      <Route path="/online" component={Online} />
      <Route path="/find" component={Find} />
      <Route path="/decision" component={DecisionPage} />
      <Route path="/lane" component={LaneGate} />
      <Route path="/finish" component={Finish} />
      <Route path="/camera" component={CameraImage} />
    </Switch>
  );
};

function App() {
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [startApp, setStartApp] = useState(false);

  const handleStartApp = () => {
    setStartApp(true);
    setShowLandingPage(false);
  };

  useEffect(() => {
    msalInstance.handleRedirectPromise().then((response) => {
      if (response) {
        setStartApp(true);
        setShowLandingPage(false);
      } else {
        const accounts = msalInstance.getAllAccounts();
        if (accounts && accounts.length > 0) {
          setStartApp(true);
          setShowLandingPage(false);
        }
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div className="App">
      {showLandingPage ? (
        <header className="App-header">
          <div onClick={handleStartApp}>
            <TypingText />
          </div>
        </header>
      ) : (
        startApp && (
          <MsalProvider instance={msalInstance}>
            <AuthProvider>
              <WebSocketProvider>
                <AdminSiteIdProvider>
                  <Router basename={basename}>
                    <AppContent />
                  </Router>
                </AdminSiteIdProvider>
              </WebSocketProvider>
            </AuthProvider>
          </MsalProvider>
        )
      )}
    </div>
  );
}

export default App;
